import React, { useEffect, useState } from "react";
import Meta from "../components/Meta";
import Section from "../components/Section";
import SectionHeader from "../components/SectionHeader";
import Navbar from "./../components/Navbar";
//import Alert from "react-bootstrap/Alert";
//import "bootstrap/dist/css/bootstrap.min.css";
function MyAccount(props) {
  const [userId, setUserId] = useState("");

  useEffect(() => {
    // Get value stored in localStorage
    const storedUserId = localStorage.getItem("__user_id");
    if (storedUserId) {
      // Delete "" from __user_id
      const cleanedUserId = storedUserId.replace(/"/g, "");
      setUserId(cleanedUserId);
    }
  }, []);

  return (
    <>
      {/* {["warning"].map(
        (variant) =>
          auth.user.CurrentTrial && (
            <Alert key={variant} variant={variant}>
              You are currently on a free trial which expires on{" "}
              {moment(auth.user.subscriptionEndDate).format("MMMM Do YYYY")}{" "}
              <Alert.Link href="myAccount">Upgrade here</Alert.Link>
            </Alert>
          ),
      )} */}

      <Navbar bgColor="bg-white" />
      <Meta title="My Account" />
      <script src="test.js"></script>
      <Section
        size="md"
        bgColor="bg-white"
        bgImage=""
        bgImageOpacity={1}
        textColor=""
      >
        <div>
          <SectionHeader
            title=""
            subtitle=""
            strapline=""
            className="text-center"
          />
          <iframe
            src={`/omniout/myAccount.html?c__accountToken=${userId}`}
            style={{ width: "98%", height: "750px", margin: "0 20px 0 20px" }}
            title="My Account"
          />
        </div>
      </Section>
    </>
  );
}

export default MyAccount;
