import React from "react";
import Meta from "./../components/Meta";
import DashboardSection from "./../components/DashboardSection";
import { requireAuth } from "./../util/auth";
import Navbar from "./../components/Navbar";
import Alert from "react-bootstrap/Alert";
import { useAuth } from "./../util/auth";
import moment from "moment";
import "bootstrap/dist/css/bootstrap.min.css";
function DashboardPage(props) {
  const auth = useAuth();
  return (
    <>
      {["warning"].map(
        (variant) =>
          auth.user.CurrentTrial && (
            <Alert key={variant} variant={variant}>
              You are currently on a free trial which expires on{" "}
              {moment(auth.user.subscriptionEndDate).format("MMMM Do YYYY")}{" "}
              <Alert.Link href="myAccount">Upgrade here</Alert.Link>
            </Alert>
          )
      )}

      <Navbar bgColor="bg-white" />

      <Meta title="Dashboard" />
      <DashboardSection
        title="Dashboard"
        subtitle=""
        strapline=""
        size="md"
        bgColor="bg-white"
      />
    </>
  );
}

export default requireAuth(DashboardPage);
