import React, { useState } from "react";
import { Transition, Dialog } from "@headlessui/react";
import TextField from "./TextField";
import Button from "./Button";
import LoadingIcon from "./LoadingIcon";
import { createManagementUsers } from "../util/db";
import { useAuth } from "./../util/auth";

function AddUserModal({ onClose }) {
  const auth = useAuth();

  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [showModal, setShowModal] = useState(true);
  const [pending, setPending] = useState(false);
  const [formAlert, setFormAlert] = useState(null);

  const onSubmit = async (e) => {
    e.preventDefault();
    await createUsersInUserManagement();
  };

  async function createUsersInUserManagement() {
    try {
      // Call function to create user in management
      await createManagementUsers(auth.user.uid, {
        firstName,
        lastName,
        email,
      });

      // After successful creation, close the modal
      console.log("Closing modal...");
      setShowModal(false);
      onClose();
    } catch (error) {
      console.error("Error creating users:", error);
    }
  }

  const handleCloseButtonClick = () => {
    setShowModal(false);
    onClose(); 
  };

  return (
    <Transition appear={true} show={showModal}>
      <Dialog
        as="div"
        className="overflow-y-auto fixed inset-0 z-10"
        onClose={handleCloseButtonClick}
      >
        <div className="px-4 min-h-screen text-center">
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75" />
          </Transition.Child>
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="inline-block overflow-hidden p-6 my-8 w-full max-w-md text-left align-middle bg-white rounded-2xl shadow-xl transition-all transform">
              <div className="absolute top-0 right-0 mt-4 mr-4">
                <button
                  type="button"
                  onClick={handleCloseButtonClick}
                  className="text-gray-400 hover:text-gray-500"
                >
                  <span className="sr-only">Close</span>
                  <svg
                    className="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
              <Dialog.Title
                as="h3"
                className="text-lg font-medium leading-6 text-gray-900"
              >
                Add User
              </Dialog.Title>
              <div className="mt-4">
                <form className="space-y-4" onSubmit={onSubmit}>
                  <TextField
                    type="text"
                    id="firstName"
                    name="firstName"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    placeholder="First Name"
                    inputClassName="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    required
                  />
                  <TextField
                    type="text"
                    id="lastName"
                    name="lastName"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    placeholder="Last Name"
                    inputClassName="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    required
                  />
                  <TextField
                    type="email"
                    id="email"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Email"
                    inputClassName="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    required
                  />
                  <div className="mt-4 flex justify-end space-x-4">
                    <Button
                      size="md"
                      variant="simple"
                      onClick={handleCloseButtonClick}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      size="md"
                      disabled={pending}
                      isBlock={true}
                      className="w-20"
                    >
                      {!pending && <>Add User</>}
                      {pending && <LoadingIcon className="w-5" />}
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}

export default AddUserModal;
