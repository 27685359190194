import React, { useState, useEffect } from "react";
import AddUserModal from "./AddUserModal";
import { useUserManagement, getUser } from "../util/db";
import { useAuth } from "./../util/auth";

const UserManagementComponent = (props) => {
  const auth = useAuth();
  const {
    data: userManagementData,
    status: isLoadingData,
    error: isErrorData,
  } = useUserManagement(auth.user.uid);

  const [showModal, setShowModal] = useState(false);
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    fetchUserData(auth.user.uid);
  }, [auth]);

  useEffect(() => {
    if (userManagementData && userData) {
      setIsButtonDisabled(userManagementData.length >= userData.quantity);
    }
  }, [userManagementData, userData]);

  async function fetchUserData(uid) {
    try {
      const userData = await getUser(uid);
      setUserData(userData);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  }

  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  function handleOpenModal() {
    if (!userManagementData) {
      console.error("User data management is not available.");
      return;
    } else {
      setShowModal(true);
    }
  }

  function handleCloseModal() {
    setShowModal(false);
  }

  return (
    <div className="max-w-screen-lg mx-auto flex flex-col space-y-8">
      <div className="flex justify-between items-center">
        {userData && (
          <span className="text-gray-500">
            Max Users Creation: {userData.quantity}
          </span>
        )}
        <button
          className={`px-4 py-2 rounded ${isButtonDisabled ? "bg-gray-300 cursor-not-allowed" : "bg-green-500 text-white"}`}
          onClick={handleOpenModal}
          disabled={isButtonDisabled}
          style={{ display: userData ? "inline-block" : "none" }}
        >
          Add User
        </button>
      </div>
      {showModal && <AddUserModal onClose={handleCloseModal} />}
      <div className="flex-1">
        {userManagementData && (
          <table className="w-full border-collapse border border-gray-300">
            <thead>
              <tr>
                <th className="border border-gray-300 p-2">#</th>
                <th className="border border-gray-300 p-2">Email</th>
                <th className="border border-gray-300 p-2">First Name</th>
                <th className="border border-gray-300 p-2">Last Name</th>
              </tr>
            </thead>
            <tbody>
              {userManagementData.map((user, index) => (
                <tr key={index}>
                  <td className="border border-gray-300 p-2">{index + 1}</td>
                  <td className="border border-gray-300 p-2">{user.email}</td>
                  <td className="border border-gray-300 p-2">
                    {user.firstName}
                  </td>
                  <td className="border border-gray-300 p-2">
                    {user.lastName}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default UserManagementComponent;
