import React from "react";
import Meta from "./../components/Meta";
import Section from "./../components/Section";
import SectionHeader from "./../components/SectionHeader";
import Navbar from "./../components/Navbar";
//Duplicate this page
function PricingPage(props) {
  return (
    <>
             <Navbar bgColor="bg-white" />
      <Meta title="Pricing" />
      <script src="test.js"></script>
      <Section
        size="md"
        bgColor="bg-white"
        bgImage=""
        bgImageOpacity={1}
        textColor=""
      >
        <div className="space-y-10 container">
          <SectionHeader
            title="Choose the best plan"
            subtitle="Get to know our premium solutions and get your start up off the ground in no time. Inspiring results from the first days."
            strapline="Pricing"
            className="text-center"
          />
          <iframe src="/omniout/" style={{'width': '100%', 'height': '950px'}} title="Pricing" />
        </div>
      </Section>
    </>
  );
}

export default PricingPage;
