import React from "react";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import DashboardItems from "./DashboardItems";
import { Link } from "./../util/router";
import { useAuth } from "./../util/auth";

function DashboardSection(props) {
  const auth = useAuth();
  const canUseStar =
    auth.user.planActive && auth.user.CurrentPlan === "Organization";

  return (
    <Section
      size={props.size}
      bgColor={props.bgColor}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      textColor={props.textColor}
    >
      <div className="container">
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          strapline={props.strapline}
          className="text-center"
        />
        <div className="flex flex-wrap">
          <div className="p-4 w-full md:w-1/2">
            <div className="rounded border border-gray-200">
              <DashboardItems />
            </div>
          </div>
          <div className="p-4 w-full md:w-1/2">
            <div className="p-6 rounded border border-gray-200 prose prose-a:text-blue-600 max-w-none">
              <h3 className="mb-4">Item Management</h3>
              <p></p>
            </div>
            <p></p>
            <span>
              {canUseStar && (
                <div style={{ color: "blue", lineHeight: 3, padding: 20 }}>
                  <p>
                    The component on your left enables you to add items. You
                    subscription level is Organization, which enables you to
                    star items
                  </p>
                </div>
              )}

              {!canUseStar && (
                <div style={{ color: "blue", lineHeight: 3, padding: 20 }}>
                  <p>
                    The component on your left enables you to add items. You
                    will need to upgrade your subscription to organization to
                    star items
                  </p>
                </div>
              )}
            </span>
            <div className="w-full md:w-full">
              <div className="p-6 rounded border border-gray-200 prose prose-a:text-blue-600 max-w-none">
                <h3 className="mb-4">User Information</h3>
                <p></p>
              </div>
            </div>
            <span>
              <div style={{ color: "blue", lineHeight: 1, padding: 20 }}>
                You are signed in as <strong>{auth.user.email}</strong>.
              </div>
            </span>

            <span>
              <div style={{ color: "blue", lineHeight: 1, padding: 20 }}>
                You are subscribed to the{" "}
                <strong>{auth.user.CurrentPlan} plan</strong>.
              </div>
            </span>

            <span>
              <div style={{ color: "blue", lineHeight: 1, padding: 20 }}>
                User quantity: <strong>{auth.user.quantity} </strong>
              </div>
            </span>
            {auth.user.CurrentTrial && (
              <div style={{ color: "blue", lineHeight: 1, padding: 20 }}>
                You are currently on a free trial which expires on{" "}
                <strong> {auth.user.subscriptionEndDate} </strong>
              </div>
            )}
          </div>
        </div>
      </div>
    </Section>
  );
}

export default DashboardSection;
