import Meta from "../components/Meta";
import Section from "../components/Section";
import SectionHeader from "../components/SectionHeader";
import Navbar from "./../components/Navbar";
import UserManagementComponent from "../components/UserManagement";

function UserManagement(props) {

  return (
    <>
      <Navbar bgColor="bg-white" />
      <Meta title="User Management" />
      <script src="test.js"></script>
      <Section
        size="md"
        bgColor="bg-white"
        bgImage=""
        bgImageOpacity={1}
        textColor=""
      >
        <div>
          <SectionHeader
            title="User Management"
            subtitle=""
            strapline=""
            className="text-center"
          />
          <UserManagementComponent />
        </div>
      </Section>
    </>
  );
}

export default UserManagement;
